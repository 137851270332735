<script>
import { BFormTextarea, BInputGroup, BButtonGroup } from 'bootstrap-vue'

import { global } from './utils'
export default {
  name: 'EventTicket',
  components: {
    BFormTextarea,
    BInputGroup,
    BButtonGroup,
  },
  props: {
    user: [Object, String],
    ticketProp: Object,
    language: String,
    ticketLinkUrl: String,
  },
  data() {
    return {
      global,
      errors: {},
      loadings: {},
      ticket: {},
      ticketTitlePreview: {},
      ticketDescriptionPreview: {},
      ticketAmountPreview: 0,
    }
  },
  methods: {
    setError(name) {
      this.$set(this.errors, name, true)
    },
    clearError(name) {
      this.$set(this.errors, name, false)
    },
    setLoading(name) {
      this.$set(this.loadings, name, true)
    },
    clearLoading(name) {
      this.$set(this.loadings, name, false)
    },
    setTicketTitlePreview(value) {
      if (this.ticketTitlePreview[this.language]) {
        this.$set(this.ticketTitlePreview[this.language], 'value', value)
      } else {
        const item = {
          [this.language]: {
            value: value,
          },
        }
        this.$set(this.ticketTitlePreview, [this.language], item[this.language])
      }
      this.$emit('ticket-title-updated', {
        id: this.ticket.id,
        title: this.ticketTitlePreview,
      })
    },
    broadcastTicketAmountChange(value) {
      this.$emit('ticket-amount-updated', {
        id: this.ticket.id,
        amount: value,
      })
    },
    setTicketDescriptionPreview(value) {
      if (this.ticketDescriptionPreview[this.language]) {
        this.$set(this.ticketDescriptionPreview[this.language], 'value', value)
      } else {
        const item = {
          [this.language]: {
            value: value,
          },
        }
        this.$set(
          this.ticketDescriptionPreview,
          [this.language],
          item[this.language]
        )
      }
    },
    saveTicketTitle() {
      this.isTicketTitleModified && this.saveTicketTitleEndpoint()
    },
    saveTicketTitleEndpoint() {
      this.setLoading('saveTicketTitleEndpoint')
      this.clearError('saveTicketTitleEndpoint')
      this.global.debug(
        'setting ticket title to: ' +
          this.ticketTitlePreview[this.language].value
      )
      const body = { title: this.ticketTitlePreview }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/rewards/' +
            this.ticket.id +
            '/language/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (
            this.ticket.title &&
            this.ticket.title[this.language] &&
            this.ticket.title[this.language].value
          ) {
            this.$set(
              this.ticket.title[this.language],
              'value',
              this.ticketTitlePreview[this.language].value
            )
          } else {
            const item = {
              [this.language]: {
                value: this.ticketTitlePreview[this.language].value,
              },
            }
            this.$set(this.ticket.title, [this.language], item[this.language])
          }
          if (this.ticketTitlePreview[this.language].value === '') {
            this.$delete(this.ticketTitlePreview, [this.language])
            this.$delete(this.ticket.title, [this.language])
          }
          this.$emit('ticket-title-saved', {
            id: this.ticket.id,
            title: this.ticketTitlePreview,
          })
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveTicketTitleEndpoint')
          this.clearLoading('saveTicketTitleEndpoint')
        })
        .finally(() => this.clearLoading('saveTicketTitleEndpoint'))
    },
    removeTicket(confirmText = false) {
      let confirm = false
      if (confirmText) {
        confirm = window.confirm(confirmText)
      } else {
        confirm = window.confirm('This action cannot be undone, are you sure?')
      }
      if (confirm) {
        this.removeTicketEndpoint()
      } else {
        this.global.debug('removeTicket canceled')
      }
    },
    removeTicketEndpoint() {
      this.setLoading('removeTicketEndpoint')
      this.clearError('removeTicketEndpoint')
      this.global.debug('removing ticket: ' + this.ticket.id)
      this.axios
        .delete(this.$root.apiUrl + '/agitator/rewards/' + this.ticket.id, {
          headers: {
            Authorization: this.authHeader,
          },
        })
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          // TODO: send event to notify of removal
          this.$emit('ticket-removed', this.ticket.id)
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('removeTicketEndpoint')
          this.clearLoading('removeTicketEndpoint')
        })
        .finally(() => this.clearLoading('removeTicketEndpoint'))
    },
    saveTicketDescription() {
      this.isTicketDescriptionModified && this.saveTicketDescriptionEndpoint()
    },
    saveTicketDescriptionEndpoint() {
      this.setLoading('saveTicketDescriptionEndpoint')
      this.clearError('saveTicketDescriptionEndpoint')
      this.global.debug(
        'setting ticket description to: ' +
          this.ticketDescriptionPreview[this.language].value
      )
      const body = { description: this.ticketDescriptionPreview }
      this.axios
        .patch(
          this.$root.apiUrl +
            '/agitator/v2/rewards/' +
            this.ticket.id +
            '/language/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          if (
            this.ticket.description &&
            this.ticket.description[this.language] &&
            this.ticket.description[this.language].value
          ) {
            this.$set(
              this.ticket.description[this.language],
              'value',
              this.ticketDescriptionPreview[this.language].value
            )
          } else {
            const item = {
              [this.language]: {
                value: this.ticketDescriptionPreview[this.language].value,
              },
            }
            this.$set(
              this.ticket.description,
              [this.language],
              item[this.language]
            )
          }
          if (this.ticketDescriptionPreview[this.language].value === '') {
            this.$delete(this.ticketDescriptionPreview, [this.language])
            this.$delete(this.ticket.description, [this.language])
          }
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveTicketDescriptionEndpoint')
          this.clearLoading('saveTicketDescriptionEndpoint')
        })
        .finally(() => this.clearLoading('saveTicketDescriptionEndpoint'))
    },
    saveTicketAmount() {
      this.isTicketAmountModified && this.saveTicketAmountEndpoint()
    },
    saveTicketAmountEndpoint() {
      this.setLoading('saveTicketAmountEndpoint')
      this.clearError('saveTicketAmountEndpoint')
      this.global.debug('setting ticket amount to: ' + this.ticketAmountPreview)
      const body = { amount: this.ticketAmountPreview }
      this.axios
        .patch(
          this.$root.apiUrl + '/agitator/rewards/' + this.ticket.id + '/',
          body,
          {
            headers: {
              Authorization: this.authHeader,
            },
          }
        )
        .then((response) => {
          this.global.debug(response.data)
          // set client side
          this.ticket.amount = this.ticketAmountPreview
          this.$emit('ticket-amount-saved', {
            id: this.ticket.id,
            amount: this.ticketAmountPreview,
          })
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveTicketAmountEndpoint')
          this.clearLoading('saveTicketAmountEndpoint')
        })
        .finally(() => this.clearLoading('saveTicketAmountEndpoint'))
    },
    saveTicket() {
      if (this.isTicketModified) {
        this.saveTicketTitle()
        this.saveTicketDescription()
        this.saveTicketAmount()
      }
    },
    resetTicket() {
      if (this.isTicketModified) {
        this.ticket = JSON.parse(JSON.stringify(this.ticketProp))
        this.ticketTitlePreview = JSON.parse(JSON.stringify(this.ticket.title))
        this.ticketDescriptionPreview = JSON.parse(
          JSON.stringify(this.ticket.description)
        )
        this.ticketAmountPreview = this.ticket.amount
      }
    },
    formatNumber(number, locale) {
      return new Intl.NumberFormat(locale).format(number)
    },
    formatNumberInput(value) {
      return parseInt(value) || 0
    },
  },
  computed: {
    authHeader() {
      return this.user && this.user.token && 'Bearer ' + this.user.token
    },
    ticketTitle() {
      return (
        this.ticket.title &&
        this.ticket.title[this.language] &&
        this.ticket.title[this.language].value
      )
    },
    ticketTitleFallback() {
      return (
        this.ticket.title &&
        this.ticket.title[Object.keys(this.ticket.title)[0]] &&
        this.ticket.title[Object.keys(this.ticket.title)[0]].value
      )
    },
    ticketDescription() {
      return (
        this.ticket.description &&
        this.ticket.description[this.language] &&
        this.ticket.description[this.language].value
      )
    },
    ticketDescriptionFallback() {
      return (
        this.ticket.description &&
        this.ticket.description[Object.keys(this.ticket.description)[0]] &&
        this.ticket.description[Object.keys(this.ticket.description)[0]].value
      )
    },
    ticketAmount() {
      return this.ticket.amount
    },
    isTicketTitleLoading() {
      return (
        this.loadings['saveTicketTitleEndpoint'] &&
        this.loadings['saveTicketTitleEndpoint'] === true
      )
    },
    isTicketTitleModified() {
      if (this.ticket.title && this.ticket.title[this.language]) {
        return (
          JSON.stringify(this.ticket.title[this.language]) !==
          JSON.stringify(this.ticketTitlePreview[this.language])
        )
      } else {
        return (
          this.ticketTitlePreview[this.language] &&
          this.ticketTitlePreview[this.language].value !== ''
        )
      }
    },
    isTicketDescriptionLoading() {
      return (
        this.loadings['saveTicketDescriptionEndpoint'] &&
        this.loadings['saveTicketDescriptionEndpoint'] === true
      )
    },
    isTicketDescriptionModified() {
      if (this.ticket.description && this.ticket.description[this.language]) {
        return (
          JSON.stringify(this.ticket.description[this.language]) !==
          JSON.stringify(this.ticketDescriptionPreview[this.language])
        )
      } else {
        return (
          this.ticketDescriptionPreview[this.language] &&
          this.ticketDescriptionPreview[this.language].value !== ''
        )
      }
    },
    isTicketAmountLoading() {
      return (
        this.loadings['saveTicketAmountEndpoint'] &&
        this.loadings['saveTicketAmountEndpoint'] === true
      )
    },
    isTicketAmountModified() {
      return this.ticket.amount !== this.ticketAmountPreview
    },
    isTicketModified() {
      return (
        this.isTicketAmountModified ||
        this.isTicketTitleModified ||
        this.isTicketDescriptionModified
      )
    },
    isRemoveTicketLoading() {
      return (
        this.loadings['removeTicketEndpoint'] &&
        this.loadings['removeTicketEndpoint'] === true
      )
    },
  },
  mounted() {
    this.ticket = JSON.parse(JSON.stringify(this.ticketProp))
    this.ticketTitlePreview = JSON.parse(JSON.stringify(this.ticket.title))
    this.ticketDescriptionPreview = JSON.parse(
      JSON.stringify(this.ticket.description)
    )
    this.ticketAmountPreview = this.ticket.amount
  },
}
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
